<template>
    <div class="app-box">
        <div class="title-box">
            <div class="line"></div>
            <p class="title">视频分组</p>
        </div>
        <a-divider style="margin-left: -10px" />

        <div class="add-btn-box">
            <a-button type="primary" @click="toAdd">
                <a-icon type="plus-circle" />
                <span>创建</span>
            </a-button>
        </div>
        <div class="ant-table-wrapper">
            <div class="ant-spin-nested-loading">
                <div class="ant-spin-container">
                    <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                        <div class="ant-table-content">
                            <div class="ant-table-body">
                                <table class="">
                                    <colgroup>
                                        <col />
                                        <col />
                                        <col style="width: 185px; min-width: 185px;" />
                                    </colgroup>
                                    <thead class="ant-table-thead">
                                        <tr>
                                            <th key="0" class="">
                                                <span class="ant-table-header-column">
                                                    <div>
                                                        <span class="ant-table-column-title">排序</span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th key="name" class="">
                                                <span class="ant-table-header-column">
                                                    <div>
                                                        <span class="ant-table-column-title">分组名称</span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th key="count" class="">
                                                <span class="ant-table-header-column">
                                                    <div>
                                                        <span class="ant-table-column-title">设备数</span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th key="operation"
                                                class="ant-table-row-cell-break-word ant-table-row-cell-last">
                                                <span class="ant-table-header-column">
                                                    <div>
                                                        <span class="ant-table-column-title">操作</span>
                                                    </div>
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody @end="onEnd" v-bind="data" :animation="300" is='vuedraggable'
                                        class="ant-table-tbody"
                                        style="display: table-row-group;vertical-align: middle;border-color: inherit;">
                                        <tr class="ant-table-row ant-table-row-level-0" :data-row-key="item.group_id"
                                            v-for="(item, index) in data" :key="item.group_id">
                                            <td class="">{{ index + 1 }}</td>
                                            <td class="">{{ item.name }}</td>
                                            <td class="">{{ item.count }}</td>
                                            <td class="ant-table-row-cell-break-word">
                                                <div class="control-box">
                                                    <a-space>
                                                        <a @click="toEdit(item)">编辑</a>
                                                        <a-popconfirm title="确认删除该分组" ok-text="是" cancel-text="否"
                                                            @confirm="del(item)">
                                                            <a style="color: #E0352B">删除</a>
                                                        </a-popconfirm>
                                                        <a @click="tobind(item)">关联摄像头</a>
                                                    </a-space>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-modal :title="(info.group_id == '' ? '创建' : '编辑') + '监控分组'" :visible="infovisible"
            :confirm-loading="confirmLoading" @ok="infohandleOk" @cancel="infohandleCancel" class="info-box">
            <div v-if="addSubmit" class="submit-success">
                <div class="img">
                    <a-icon type="check" />
                </div>
                <a-row>
                    <label>创建成功</label>
                </a-row>
                <a-row>
                    <a-buttom class="button" @click="addTobind">去添加摄像头</a-buttom>
                </a-row>
            </div>
            <a-form-model :model="info" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" v-else>
                <a-form-model-item label="分组名">
                    <a-input v-model="info.name" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>


        <!-- 关联抽屉 -->
        <a-drawer title="关联摄像机" placement="right" width="750" :closable="false" :visible="relationvisible"
            @close="relationClose">
            <a-form-model class="form-right" :colon="false" layout="inline" :model="relationquery" labelAlign="left"
                @submit="relationHandleSearch" @submit.native.prevent>
                <a-row>
                    <a-form-model-item label="分组名称">
                        {{ list_group_name }}
                    </a-form-model-item>
                </a-row>
                <a-row>
                    <a-form-model-item style="float: right; margin-right: 0">
                        <a-button type="primary" html-type="submit" size="small">
                            查询
                        </a-button>
                        <a-button @click="relationReset" size="small" style="margin-left: 10px;">重置</a-button>
                    </a-form-model-item>
                    <a-form-model-item label="设备名称">
                        <a-input v-model="relationquery.device_name" placeholder="设备名称" size="small" />
                    </a-form-model-item>
                    <a-form-model-item label="设备编号">
                        <a-input v-model="relationquery.device_number" placeholder="设备编号" size="small" />
                    </a-form-model-item>
                </a-row>
                <a-row>
                    <a-tabs v-model="relationquery.type" @change="callback">
                        <a-tab-pane :key="1" tab="已关联">
                        </a-tab-pane>
                        <a-tab-pane :key="2" tab="未关联" force-render>
                        </a-tab-pane>
                    </a-tabs>
                </a-row>
            </a-form-model>
            <a-empty v-show="relationData.length == 0">
                <span slot="description">暂无关联摄像头 </span>
                <a-button type="primary" @click="changeRelation">
                    去关联
                </a-button>
            </a-empty>
            <a-table v-show="relationData.length != 0" class="main-table" :pagination="{
                current: relationquery.page,
                total: relationpagetotal
            }" @change="relationListChange" :columns="relationColumns" :data-source="relationData" rowKey="id"
                style="margin-top: 14px" v-viewer>
                <span slot="class_type" slot-scope="text, record">
                    {{ text == 1 ? '消防' : (text == 2 ? '监控' : (text == 3 ? '门禁' : '网络设备')) }}
                </span>
                <span slot="icon" slot-scope="text, record">
                    <img :src="record.icon" class="img-qr">
                </span>
                <span slot="action" slot-scope="text, record">
                    <a size="small" v-if="relationquery.type == 2" @click="bind(record)">关联</a>
                    <a size="small" v-else @click="unbind(record)" style="color: #000000;">解除</a>
                </span>
            </a-table>
        </a-drawer>
    </div>
</template>

<script>
import {
    domain
} from '@/siteInfo.js'
import { get } from 'js-cookie'
import { type } from 'os'
import vuedraggable from 'vuedraggable';
export default {
    components: { vuedraggable },
    data() {
        let rulesValidator = (rule, value, callback) => {
            if (value.length == 0) {
                callback(new Error("请选择角色权限"));
            } else {
                callback();
            }
        };
        return {
            query: {
                page: 1,
                limit: 10
            },
            relationpagetotal: 0,
            relationquery: {
                page: 1,
                limit: 10,
                type: 1
            },
            relationData: [],
            pagetotal: 0,
            relationColumns: [
                {
                    title: '设备类型',
                    dataIndex: 'class_type',
                    scopedSlots: {
                        customRender: 'class_type'
                    }
                },
                // {
                // 	title: '设备类型',
                // 	dataIndex: 'device_type_name'
                // },
                {
                    title: '设备名称',
                    dataIndex: 'device_name'
                },
                {
                    title: '设备编号',
                    dataIndex: 'device_number'
                },
                {
                    title: '操作',
                    scopedSlots: {
                        customRender: 'action'
                    },
                    // width: "20%"
                }
            ],
            infovisible: false,
            list_group_id: '',
            list_group_name: '',
            addSubmit: false,
            info: {
                name: '',
                group_id: ''
            },
            relationInfo: {},
            relationvisible: false,
            data: [],
            confirmLoading: false,
        }
    },
    created() {
        this.getList()
    },
    methods: {
        infohandleCancel() {
            this.initinfoForm()
        },
        infohandleOk() {
            let label = ''
            if (this.info.group_id == '') {
                label = 'videoAdd'
            } else {
                label = 'videoUpdate'
            }
            if (this.info.name == '' || this.info.name == undefined || this.info.name == null) {
                this.$message.error('分组名不能为空', 1.5)
            }
            this.confirmLoading = true
            this.$post("dictionary/" + label, this.info)
                .then(res => {
                    let { code, msg, data } = res
                    this.confirmLoading = false
                    if (code == 0) {
                        this.$message.success(msg, 1.5)
                        //添加成功的时候需要去跳转
                        if (this.info.group_id == '') {
                            this.list_group_id = res.data.group_id
                            this.list_group_name = res.data.name
                            this.addSubmit = true
                        } else {
                            this.infovisible = false
                            this.initinfoForm()
                        }
                        this.getList()
                    } else {
                        this.$message.error(msg, 1.5)
                        this.confirmLoading = false
                    }
                })
                .catch(err => {
                    this.confirmLoading = false
                })
        },
        onEnd({ to }) {
            const children = to.children
            const arr = []
            for (let i = 0; i < children.length; i++) {
                arr.push(children[i].dataset.rowKey)
            }
            this.$post(domain + '/admin/v1/dictionary/videoSort', {
                'groups': arr,
            }).then(res => {
                if (res.code === 0) {
                    this.$message.success('排序成功')
                    this.getList()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        toEdit(item) {
            this.$post(domain + '/admin/v1/dictionary/videoInfo', {
                'group_id': item.group_id,
            }).then(res => {
                if (res.code === 0) {
                    this.info.name = res.data.name
                    this.info.group_id = res.data.group_id
                    this.infovisible = true;
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        del(item) {
            this.$post(domain + '/admin/v1/dictionary/videoDelete', {
                'group_id': item.group_id,
            }).then(res => {
                if (res.code === 0) {
                    this.$message.success(res.msg)
                    this.getList()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        initinfoForm() {
            this.infovisible = false
            this.info = {
                name: '',
                group_id: ''
            }
            this.list_group_id = ''
            this.list_group_name = ''
            this.addSubmit = false
        },
        getList() {
            this.$post(domain + '/admin/v1/dictionary/videoList', this.query).then(res => {
                if (res.code === 0) {
                    this.data = res.data.list
                    this.pagetotal = res.data.total
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        toAdd() {
            this.infovisible = true;
        },
        addTobind() {
            this.relationvisible = true
            this.relationList()
            this.infovisible = false
            this.info = {
                name: '',
                group_id: ''
            }
        },
        tobind(item) {
            this.relationvisible = true
            this.list_group_id = item.group_id
            this.list_group_name = item.name
            this.relationList()
        },
        relationClose() {
            this.list_group_id = ''
            this.list_group_name = ''
            this.relationvisible = false;
            this.addSubmit = false
        },
        relationHandleSearch() {
            this.relationList()
        },
        relationReset() {
            this.relationquery = {
                page: 1,
                limit: this.relationquery.limit,
                type: 1
            }
            this.relationList()
        },
        relationListChange(e) {
            this.relationquery.page = e.current
            this.relationList();
        },
        changeRelation() {
            this.relationquery.type = 2
            this.relationList()
        },
        callback(key) {
            this.relationquery.page = 1
            this.relationList()
        },
        relationList() {
            console.log('参数打印', this.relationquery)
            this.relationquery.group_id = this.list_group_id
            this.$post(domain + '/admin/v1/dictionary/videoDeviceList', this.relationquery).then(res => {
                if (res.code === 0) {
                    this.relationData = res.data.list
                    console.log('我是不是空数组', this.relationData)
                    this.relationpagetotal = res.data.total
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        bind(item) {
            let obj = {
                'group_id': this.list_group_id,
                'device_id': item.device_id,
            }
            this.$post(domain + '/admin/v1/dictionary/videoBind', obj)
                .then(res => {
                    let { code, msg, data } = res
                    if (code == 0) {
                        this.$message.success(msg, 1.5)
                        this.relationList()
                        this.getList()
                    } else {
                        this.$message.error(msg, 1.5)
                    }
                })
                .catch(err => {
                })
        },
        unbind(item) {
            let obj = {
                'group_id': this.list_group_id,
                'device_id': item.device_id,
            }
            this.$post(domain + '/admin/v1/dictionary/videoUnbind', obj)
                .then(res => {
                    let { code, msg, data } = res
                    if (code == 0) {
                        this.$message.success(msg, 1.5)
                        this.relationList()
                        this.getList()
                    } else {
                        this.$message.error(msg, 1.5)
                    }
                })
                .catch(err => {
                })
        }
    }
}
</script>

<style lang="less" scoped>
.img-box {
    overflow: hidden;
    display: inline-block;

    .img-icon {
        transform: translateX(-100px);
        filter: drop-shadow(blue 100px 0)
    }
}

.info-box {
    width: 518px;
    height: 320px;
    background: #FEFFFF;
    border: 1px solid #FFFFFF;

    .submit-success {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .img {
        width: 57px;
        height: 57px;
        background: #20B759;
        border-radius: 50%;
        font-size: 40px;
        color: #fff;
        line-height: 57px;
        text-align: center;
        margin-bottom: 22px;
    }

    .button {
        display: block;
        width: 151px;
        height: 31px;
        background: #597EF7;
        border-radius: 2px;
        border: 1px solid #979797;
        line-height: 31px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fff;
        margin-top: 47px;
    }
}

.title-box {
    display: flex;
    align-items: center;
    margin-left: 8px;

    .line {
        width: 5px;
        height: 14px;
        background-color: #4E80F8;
    }

    .title {
        color: #383F50;
        font-size: 16px;
        margin-left: 5px;
    }
}

.img-qr {
    height: 30px;
    cursor: pointer;
}
</style>

<style lang="less">
.form-search {
    .ant-form-item-label {
        width: 70px;
    }
}
</style>
<style lang="less">
.fengmap-box {
    position: relative;
    width: 70vw;
    height: 70vh;
    overflow: hidden;

    #fengmap {
        position: absolute;
        top: 0;
        width: 100%;
        bottom: -50px;
    }
}

.feng-icon-div {
    width: 30px;
    height: 30px;
    overflow: hidden;
    background-size: 100% 100%;
    cursor: pointer;
    border-radius: 50%;
    border: 5px solid #333;
    background-color: #fff;
    box-sizing: content-box;
    margin-left: -5px;
    margin-top: -5px;

    &:hover {
        border-color: #2B6AE0;

        .img-fengmap-icon {
            filter: drop-shadow(#2B6AE0 100px 0);
        }
    }
}

.img-fengmap-icon {
    width: 24px;
    height: 24px;
    margin-left: 3px;
    margin-top: 3px;
    filter: drop-shadow(rgb(255, 0, 0) 100px 0);
    transform: translateX(-100px);
    pointer-events: none;
    user-select: none;
}

.img-fengmap-icon-green {
    width: 24px;
    height: 24px;
    margin-left: 3px;
    margin-top: 3px;
    filter: drop-shadow(rgb(2, 182, 26) 100px 0);
    transform: translateX(-100px);
    pointer-events: none;
    user-select: none;
}
</style>
